<template>
  <div class="video-card">
    <div
      class="video-check"
      :class="{ disable: video.type === 'HLS' }"
      @click="handleVideoCheck(video)"
    >
      <img
        v-if="video.isCheck"
        src="@/assets/icons/checkbox-yellow-checked.svg"
        alt=""
      />
      <img v-else src="@/assets/icons/checkbox-white-square.svg" alt="" />
    </div>
    <div class="wrap-photos" @click="showEventDetail()">
      <div class="time">
        <span>{{ detectTime }}</span> 
      </div>
      <div class="snapshot">
        <img v-lazy="video.snapshotUrl">
      </div>
    </div>
    <div class="info">
      <div class="number-index">
        <div class="number">
          <span></span>
        </div>
        <div>
          <TooltipElement :content="String(videoNo)">
          <div class="index">{{ videoNo }}</div>
          </TooltipElement>
        </div>
      </div>
      <div class="content">
        <div class="items">
          <div class="item video-title">
            <TooltipElement :content="$t('history_device')/*辨識設備*/" alwaysShow>
              <img src="@/assets/icons/Camera.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="deviceInfo">
              <span>{{ deviceInfo }}</span>
            </TooltipElement>
          </div>

          <div class="item linkedUser">
            <TooltipElement :content="$t('account_label') /*帳號名稱*/" alwaysShow>
              <img src="@/assets/icons/account-ab.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="video.user.info.name">
              <span>{{ video.user.info.name }}</span>
            </TooltipElement>
          </div>
          <div class="item">
            <TooltipElement :content="$t('video_length')/*影片長度*/" alwaysShow>
              <img src="@/assets/icons/VideoTime.svg" alt="">
            </TooltipElement>
            <span>{{ formatSeconds(video.length) }}</span>
          </div>
          <div class="item">
            <TooltipElement :content="$t('video_size')/*影片大小*/" alwaysShow>
              <img src="@/assets/icons/File.svg" alt="">
            </TooltipElement>
            <span>{{ formatBytes(video.size) }}</span>
          </div>
        </div>
        <div class="icons">
          <TooltipElement :content="$t('history_fr_open_google_map'/*開啟Google map*/)" alwaysShow>
            <img v-if="hasGps" src="@/assets/icons/google-map-icon.png" alt="" @click="goGoogleMap(event.latitude, event.longitude)">
            <!-- <img src="@/assets/icons/google-map-icon.png" alt=""> -->
          </TooltipElement>
        </div>
      </div>
    </div>
    <div class="map">
      <EventMap v-if="hasGps" :event="gpsList[0]" type="video" :centerShift="true" />
      <img v-else src="@/assets/icons/Map.svg" alt="">
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { goGoogleMap } from '@/utils/index.js'
import { formatBytes, formatSeconds, formatTime } from '@/utils/lib.js'

export default {
  name: 'VideoEventCard',
  components: {
    EventMap: () => import('@/components/Base/EventMap.vue'),
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
  },
  props: ['video', 'index'],
  data() {
    return {
      isShowTooltip: false,
    }
  },
  computed: {
    ...mapState('historyVideo', ['videoGpsMap', 'videoCurrentPage', 'pageSize']),
    videoNo() {
      return (this.videoCurrentPage - 1) * this.pageSize + this.index + 1
    },
    detectTime() {
      return formatTime(this.video.startTime)
    },
    deviceInfo() {
      return `${this.video.title}(${this.video.user.id})`
    },
    gpsList() {
      return this.videoGpsMap[this.video.id] || []
    },
    hasGps() {
      return this.gpsList.length > 0
    },
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalShow']),
    ...mapMutations('historyVideo', ['updateVideoIndex']),
    formatSeconds,
    formatBytes,
    goGoogleMap,
    showEventDetail() {
      this.updateVideoIndex(this.index)
      this.updateHistoryEventModalShow(true)
    },
    handleVideoCheck(item) {
      if (item.type === 'HLS') return
      if (item.isCheck === undefined) this.$set(item, 'isCheck', true)
      else item.isCheck = !item.isCheck
    },
  }
}
</script>

<style lang="scss" scoped>
.video-card {
  width: 100%;
  height: 100%;
  background: #4A5C7880;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  &:hover {
    background: #4A5C78cc;
  }
  // &::before {
  //   content: '';
  //   position: absolute;
  //   width: 12px;
  //   height: 100%;
  //   background: #F94144;
  //   left: 0;
  //   top: 0;
  //   border-radius: 8px 0 0 8px;
  // }
}

.video-check {
  padding: 19px 0 0 19px;
  cursor: pointer;
  &.disable {
    img {
      @include filter_FFF_50;
    }
    cursor: not-allowed;
  }
}

.wrap-photos {
  padding: 12px 12px 12px 0px;
  .time {
    margin-bottom: 12px;
  }
  .time span {
    display: inline-block;
    color: #FFFFFF;
    background: #282942;
    border-radius: 17px;
    padding: 5px 24px;
    font-size: px2rem(18);
    line-height: 24px;
  }

  .snapshot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: px2rem(210);
    min-height: px2rem(138);
    background: #ffffff1a;
    border-radius: 3px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }
    img[lazy=error] {
      width: 60%;
      height: 60%;
    }
  }
}

.info {
  width: calc(100% - px2rem(746));
  height: 100%;
  padding: px2rem(12) px2rem(16) px2rem(12) px2rem(8);
  box-sizing: border-box;
  .number-index {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    column-gap: 18px;
    .number {
      flex: 1;
      display: flex;
      align-items: center;
      line-height: 40px;
      color: #FFE99F;
      img {
        margin-right: 8px;
      }
      span {
        font-size: px2rem(24);
        font-weight: 500;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
    .index {
      min-width: 41px;
      max-width: 116px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: px2rem(20);
      text-align: center;
      line-height: 28px;
      font-weight: normal;
      color: #282942;
      background: #FFE99F;
      padding: 0 8px;
      border-radius: 17px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  .content {
    height: calc(100% - 45px);
    display: flex;
    column-gap: 20px;
    line-height: 35px;
    color: #ffffff;
    .items {
      width: calc(100% - 50px);
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        &.push-message {
          align-items: flex-start;
          img {
            margin-top: 9px;
          }
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
        span {
          font-size: px2rem(20);
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background: #ffffff33;
        margin: 1px 0 6px 0;
      }
    }
    .icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      &.unknown {
        justify-content: flex-end;
      }
    }
  }
}

.tags {
  overflow: hidden;
  .wrap-tags {
    width: 100%;
    height: 35px;
    display: flex;
    margin-bottom: 2px;
    span {
      background: #ffffff33;
      font-size: px2rem(18);
      padding: 0 12px;
      margin-right: 8px;
      border-radius: 18px;
      white-space: nowrap;
    }
  }
}

.video-title, .linkedUser, .push-message {
  width: 100%;
  display: flex;
  span {
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.map {
  width: px2rem(420);
  height: 100%;
  background: #4A5C7880;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80px;
    @include filter_FFF_20;
  }
}
</style>